import React from 'react'

import styled from 'styled-components'

const Warning = ({ setIsModal, link, modal }) => {
  return (
    <Wrapper onClick={() => setIsModal(false)}>
      <div className='centered'>
        <div
          className='modal'
          style={{ background: `url(${modal.background[0].url})` }}
        >
          <div className='flex'>
            <div className='photo'>
              <img src={modal.photo[0].url} alt='Peachie' />
            </div>

            <div className='textBlock'>
              <p>{modal.title}</p>
              <p>{modal.text}</p>
            </div>
          </div>
          <a target='_blank' rel='noreferrer' href={link}>
            <button className='button'>
              {modal.button}
              <br />
              <span>Entrer</span>
            </button>
          </a>
        </div>
      </div>
    </Wrapper>
  )
}

export default Warning

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  width: 90vw;
  height: 100vh;
  z-index: 98;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  padding: 0;
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
  }
  .modal {
    width: 438px;
    height: 235px;

    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 21px;
    background-color: #ab69af;
    text-align: center;

    .hide {
      display: none;
    }

    .flex {
      display: flex;
      justify-content: space-evenly;
    }
    .textBlock {
      width: 275px;
      align-self: center;
      font-weight: 900;
    }
    .photo {
      margin-top: 9px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      background: url(ely.jpg);
    }

    .button {
      margin: 10px;
      width: 220px;
      height: 70px;
      background: #ab69af;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 14px;
      color: #fff;
      font-weight: 600;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      span {
        text-transform: uppercase;
      }
      cursor: pointer;
      :active {
        box-shadow: none;
      }
    }
  }
`
