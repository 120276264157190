import * as React from 'react'
import { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import '../assets/sass/main.scss'

import { bgImg } from '../assets'
import { base, Layout, Hero, MyLinks, Counter, Seo } from '../components'

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      {
        seo: allAirtable(filter: { table: { eq: "Hero" } }) {
          nodes {
            data {
              miniature {
                url
              }
              googleDescription
              text
              name
            }
          }
        }
        modal: allAirtable(filter: { table: { eq: "Adults" } }) {
          nodes {
            data {
              title
              text
              photo {
                url
              }
              background {
                url
              }
              name
              button
            }
          }
        }
        links: allAirtable(
          sort: { fields: rowIndex }
          filter: { table: { eq: "Links" } }
        ) {
          nodes {
            data {
              name
              enAvant
              link
              counter
              icon
              _18
            }
          }
        }
      }
    `
  )

  const [heroTable, setHero] = useState([])
  const [linkTable, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const getHeroRecords = async () => {
    const heroTable = await base('Hero')
      .select({})
      .firstPage()
      .catch(err => console.error(err))

    const newItems = heroTable.map(record => {
      const { id, fields } = record
      return { id, fields }
    })
    setHero(newItems)
  }

  const getLinksRecords = async () => {
    const linkTable = await base('Links')
      .select({ view: 'Grid view' })
      .firstPage()
      .catch(err => console.error(err))

    const newItems = linkTable.map(record => {
      const { id, fields } = record
      return { id, fields }
    })
    setItems(newItems)
    setLoading(false)
  }

  useEffect(() => {
    getHeroRecords()
    getLinksRecords()
  }, [])

  return (
    <Layout>
      <Seo records={data.seo} />
      <div
        className='bgImg'
        style={{
          background: `url(${bgImg}) no-repeat center`,
        }}
      >
        <div className='wrapper'>
          <Hero records={data.seo} />

          <MyLinks
            linkTable={linkTable}
            links={data.links.nodes}
            modal={data.modal.nodes[0].data}
          />
          <h3>
            Visitors <br />
            {loading ? (
              <span>loading...</span>
            ) : (
              <Counter records={heroTable} />
            )}
          </h3>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
