import React from 'react'
import base from './Airtable'

const updateCounter = async tempItem => {
  tempItem[0].fields.counter++
  await base('Hero')
    .update(tempItem)
    .catch(err => console.err(err))
}

const Counter = ({ records }) => {
  const tempItem = [...records]

  if (
    tempItem.length !== 0 &&
    !localStorage.getItem('returning') &&
    !process.env.DEV
  ) {
    updateCounter(tempItem)
    localStorage.setItem('returning', true)
  }

  return (
    <div>
      {records.map(item => {
        const {
          id,
          fields: { counter },
        } = item

        return <span key={id}>{counter}</span>
      })}
    </div>
  )
}

export default Counter
