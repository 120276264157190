import React from 'react'

import { Helmet } from 'react-helmet'

const SEO = ({ records }) => {
  const imageUrl = records.nodes[0].data.miniature[0].url

  const { name, text } = records.nodes[0].data

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title='Peachie'
      meta={[
        { name: `description`, content: 'googleDescription' },
        { property: 'og:title', content: name },
        { property: 'og:site_name', content: 'Ely Peachie site name' },
        {
          property: 'og:description',
          content: text,
        },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:image',
          content: imageUrl,
        },
        { property: 'og:image:height', content: 320 },
        { property: 'og:image:width', content: 320 },
      ]}
    ></Helmet>
  )
}

export default SEO
