import React from 'react'

const Hero = ({ records }) => {
  const { name, text } = records.nodes[0].data
  return (
    <div>
      <h1>{name}</h1>
      <h3>{text}</h3>
    </div>
  )
}

export default Hero
