import React from 'react'
import { Icon } from '@iconify/react'
import onlyfansIcon from '@iconify/icons-simple-icons/onlyfans'
import tiktokIcon from '@iconify/icons-cib/tiktok'
import snapchatIcon from '@iconify/icons-cib/snapchat'
import instagramFill from '@iconify/icons-akar-icons/instagram-fill'
import twitterFill from '@iconify/icons-akar-icons/twitter-fill'
import facebookFill from '@iconify/icons-akar-icons/facebook-fill'

const Icons = ({ icon }) => {
  const iconList = {
    onlyFans: onlyfansIcon,
    tiktok: tiktokIcon,
    snapchat: snapchatIcon,
    instagram: instagramFill,
    twitter: twitterFill,
    facebook: facebookFill,
  }

  const selectIcon = icon => {
    let linkIcon = ''
    switch (icon) {
      case 'onlyFans':
        linkIcon = iconList.onlyFans
        break
      case 'tiktok':
        linkIcon = iconList.tiktok
        break
      case 'snapchat':
        linkIcon = iconList.snapchat
        break
      case 'instagram':
        linkIcon = iconList.instagram
        break
      case 'facebook':
        linkIcon = iconList.facebook
        break
      case 'twitter':
        linkIcon = iconList.twitter
        break
      default:
        linkIcon = null
    }
    return linkIcon
  }
  return <Icon icon={selectIcon(icon)} className='icon' />
}

export default Icons
