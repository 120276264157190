import React, { useState } from 'react'
import Icons from './Icons'
import { mymImg, FanslyIcon } from '../assets'
import { Warning, base } from '../components'

const increaseCount = async (tempItem, id) => {
  const tempItems = [...tempItem].map(item => {
    if (item.id === id) {
      let { id, fields } = item
      fields = { ...fields, counter: fields.counter + 1 }
      return { id, fields }
    } else {
      return item
    }
  })
  if (!process.env.DEV) {
    await base('Links')
      .update(tempItems)
      .catch(err => console.err(err))
  }
}

const MyLinks = ({ linkTable, links, modal }) => {
  const [isModal, setIsModal] = useState(false)
  const [modalLink, setModalLink] = useState(null)

  return (
    <>
      <div className='btnContainer'>
        {links.map((myLink, id) => {
          const {
            data: { name, icon, link, enAvant, _18 },
          } = myLink

          let btnClassName = enAvant ? 'btn btn-main' : 'btn'

          let imgSrc

          if (icon === 'mym') {
            imgSrc = mymImg
            btnClassName += ' mym-btn'
          }
          if (icon === 'fansly') {
            imgSrc = { FanslyIcon }
            btnClassName += 'btn-Fansly'
          }

          const IconOrImg = ({ type }) => {
            return type !== 'mym' ? (
              <Icons icon={icon} />
            ) : (
              <img src={imgSrc} alt={name} style={{ height: '14px' }} />
            )
          }

          return (
            <a
              target='_blank'
              key={id}
              rel='noreferrer'
              className={btnClassName}
              href={_18 ? null : link}
              onClick={() => {
                if (_18) {
                  setIsModal(true)
                  setModalLink(link)
                }
                increaseCount(linkTable, id)
              }}
            >
              <IconOrImg type={icon} />

              {name !== 'mym' ? name : '  '}
            </a>
          )
        })}
        {isModal && (
          <Warning setIsModal={setIsModal} link={modalLink} modal={modal} />
        )}
      </div>
    </>
  )
}

export default MyLinks
